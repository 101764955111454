.conexo-response-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
}
.conexo-found {
    margin: auto 0;
}

.result-image {
    height: 50px;
    margin-right: 20px;
}
.conexo-not-found {
    display: flex;
    align-items: center;
}
.result-text {
    margin: auto 0;
    font-weight: 500;
    text-align: center;
}

.error_title {
    text-align: center;
    color: red;
}
@media only screen and (max-width: 700px) {
    .response-wrapper {
        padding: 10px 20px;
    }
    .result-text {
        font-size: 13px;
    }
    .result-image {
        height: 30px;
    }
}
@media only screen and (max-width: 500px) {
    .response-wrapper {
        padding: 10px;
    }
    .result-text {
        font-size: 12px;
    }
    .result-image {
        height: 30px;
    }
}
@media only screen and (max-width: 350px) {
    .conexo-response-wrapper {
        padding: 10px;
    }
    .result-text {
        font-size: 11px;
    }
    .result-image {
        height: 30px;
    }
}
