.serialNumber-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px 20px;
}

.input-wrapper {
    display: flex;
    margin: 0;
    padding: 0;
}

.code-image {
    display: block;
    width: 100px;
    margin-right: 10px;
    &:hover {
        cursor: pointer;
    }
}

.input-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.scan-button {
    background-color: rgb(238, 238, 238);
    border: none;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    &:active {
        transform: scale(0.95);
    }
    &:hover {
        background-color: black;
        border: none;
        border-radius: 5px;
        color: white;
    }
}

.input-serialnumber {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    padding-left: 5px;
    height: 30px;
    padding-top: 3px;
}

.submit-button {
    border: none;
    background-color: #5ab6f3;
    border-radius: 5px;
    color: white;
    width: 100%;
    margin-top: 5px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    border: 2px solid #5ab6f3;
    &:active {
        transform: scale(0.95);
    }
}

.submit-button:hover {
    border: 2px solid #5ab6f3;
    background-color: white;
    border-radius: 5px;
    color: #5ab6f3;
}

@media only screen and (min-width: 1000px) {
    .order-text {
        font-size: 18px;
    }
    .input-serialnumber {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1000px) {
    .order-text {
        font-size: 15px;
    }
    .input-serialnumber {
        font-size: 13px;
    }
}

@media only screen and (max-width: 700px) {
    .code-image {
        width: 70px;
    }
    .order-text {
        font-size: 13px;
    }
    .input-serialnumber {
        font-size: 13px;
    }
}

@media only screen and (max-width: 500px) {
    .code-image {
        width: 70px;
    }
    .order-text {
        font-size: 12px;
    }
    .scan-button {
        margin-bottom: 2px;
        height: 25px;
        font-size: 12px;
    }
    .submit-button {
        font-size: 15px;
    }
    .serialNumber-wrapper {
        padding: 10px;
    }
    .input-serialnumber {
        font-size: 12px;
    }
}
@media only screen and (max-width: 350px) {
    .order-text {
        font-size: 11px;
    }
    .code-image {
        width: 70px;
    }
    .scan-button {
        max-width: 165px;
        margin-bottom: 2px;
        height: 25px;
        font-size: 11px;
    }

    .serialNumber-wrapper {
        padding: 10px;
    }
    .submit-button {
        font-size: 13px;
    }
}
