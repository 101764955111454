.unexpected_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 1em gray;
}
