.header_wrapper_main {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.header_wrapper_login{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.header_logo {
    max-height: 60px;
}

.icon_text {
    padding: 0;
    margin: 0;
    margin-top: -12px;
    font-size: 24px;
}

.icon_border {
    border-bottom: 2.5px solid #afcb37;
    padding: 0;
    margin-top: 10px;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .header_logo {
        max-height: 40px;
    }

    .icon_text {
        margin-top: -6px;
        font-size: 16px;
    }

    .header_wrapper {
        margin-bottom: 10px;
    }
}
