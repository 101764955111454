.main-wrapper {
    display: flex;
    margin: 0 10px;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100vw;
}
.form-wrapper {
    flex: 1;
    margin: auto;
    border: 2px solid black;
    border-radius: 10px;
    padding: 20px;
}
.form-wrapper > * {
    margin: 15px;
}

@media only screen and (max-width: 500px) {
    .main-wrapper {
        width: 95vw;
    }
    .form-wrapper > * {
        margin: 5px;
    }
    .form-wrapper {
        padding: 10px;
        max-width: 100%;
    }
}

@media only screen and (min-width: 500px) {
    .main-wrapper {
        width: 95vw;
    }
}

@media only screen and (min-width: 1000px) {
    .form-wrapper {
        max-width: 65%;
    }
    .form-wrapper > * {
        font-size: 15px;
    }
}
@media only screen and (min-width: 1200px) {
    .form-wrapper {
        max-width: 55%;
    }
    .form-wrapper > * {
        font-size: 18px;
    }
}
