.login-wrapper {
    margin-top: 60px;
}

.alert {
    background-color: rgb(255, 194, 80);
    color: white;
    max-width: 800px;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
}

.login-header {
    display: flex;
    margin: auto;
    color: white;
    background-color: #5ab6f3;
    margin-bottom: 0;
    margin-top: 0;
    font-size: var(--header-font-size);
    font-weight: 600;
    height: 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 450px;
}
.login-form {
    width: 450px;
}
.form-text {
    color: gray;
    margin-left: 10px;
    font-size: small;
    text-align: left;
}

.register-form {
    display: flex;
    max-width: 450px;
    margin: auto;
    margin-top: 0;
    box-shadow: 0 0 1em gray;
    padding: 50px;
}

.form-group {
    margin: 15px 0px;
}

.form-group > input {
    border: none;
    border-bottom: 1px solid rgb(139, 139, 139);
}

.custom-btn {
    background-color: #5ab6f3;
    color: white;
    border: #66ccff;
    width: 100%;
    &:hover {
        background-color: #5ab6f3;
        color: white;
        border: #66ccff;
        outline: none;
    }
}
.custom-btn:focus {
    background-color: #5ab6f3 !important;
    border: none;
    outline: none;
}

.impirint_data {
    margin: 30px;
    text-align: center;
}

@media only screen and (max-width: 500px) {
    .login-wrapper > * {
        max-width: 90vw;
    }
    .register-form {
        padding: 20px 40px;
    }
    .alert {
        margin-left: 5%;
    }
}

@media only screen and (max-width: 350px) {
    .register-form {
        padding: 10px 20px;
    }
}

@media only screen and (max-height: 450px) {
    .login-wrapper {
        height: 100%;
    }
    .register-form {
        margin-bottom: 20px !important;
    }
}
