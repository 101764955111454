.response-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
}
.found {
    margin: auto 0;
}

.netilion-result-image {
    height: 50px;
    margin-right: 20px;
}
.not-found {
    display: flex;
    align-items: center;
}
.result-text {
    margin: auto;
    font-weight: 500;
}
@media only screen and (max-width: 700px) {
    .response-wrapper {
        padding: 10px 20px;
    }
    .result-text {
        font-size: 13px;
    }
    .netilion-result-image {
        height: 30px;
    }
}
@media only screen and (max-width: 500px) {
    .response-wrapper {
        padding: 10px;
    }
    .result-text {
        font-size: 12px;
    }
    .netilion-result-image {
        height: 30px;
    }
}
@media only screen and (max-width: 350px) {
    .response-wrapper {
        padding: 10px;
    }
    .result-text {
        font-size: 11px;
    }
    .netilion-result-image {
        height: 30px;
    }
}
